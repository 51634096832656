import React from 'react'

import { CheckboxProps } from '../../../common/types'

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  id,
  label,
  handleChange = undefined,
  disabled = false,
  checked = false,
  intermediate = false,
  hide = false,
}) => {
  return (
    <>
      {!hide ? (
        <div className={`custom-checkbox ${disabled ? ' opacity-70' : ''} `}>
          <input
            type="checkbox"
            disabled={disabled}
            name={name}
            id={id}
            checked={checked}
            onChange={handleChange}
            className="custom-checkbox-input"
          />
          <label htmlFor={id}>
            <svg
              width="8"
              height="6"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {intermediate ? (
                <line
                  x1="0"
                  y1="3"
                  x2="8"
                  y2="3"
                  stroke="white"
                  strokeWidth="2"
                />
              ) : (
                <path
                  d="M0 3.21739L2.89883 6L8 1.06994L6.89494 0L2.89883 3.86768L1.09728 2.14745L0 3.21739Z"
                  fill="white"
                />
              )}
            </svg>
          </label>
          <span>{label}</span>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default Checkbox
