import { useState } from 'react'

import { Button, DialogModal } from '../../components/common'
import FormFieldView from '../../components/common/inputs/FormFieldView'
import { useAuthStore } from '../../store/authStore'
import ProfileChangePassword from './password/profile'

type Props = {
  isOpen: boolean
  handleProfileClose: () => void
}

const Profile = ({ isOpen, handleProfileClose }: Props) => {
  const { userData, roleData } = useAuthStore()
  const [resetPassword, setResetPassword] = useState(false)
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    value: string
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    value,
  })
  const formBuilderProps = [
    textField(
      'first_name',
      'First Name',
      'Enter First Name',
      false,
      userData?.first_name ?? '--'
    ),
    textField(
      'last_name',
      'Last Name',
      'Enter Lat Name',
      false,
      userData.last_name ?? '--'
    ),
    textField('email', 'Email', 'Enter Email', false, userData?.email ?? '--'),
    {
      ...textField(
        'mobile',
        'Mobile',
        'Enter Mobile',
        false,
        userData.mobile ?? '--'
      ),
      type: 'phone',
    },
    textField('name', 'Role', 'Enter Role', false, roleData?.name ?? '--'),
  ]
  const handleResetPassword = () => {
    setResetPassword(true)
  }

  const handleClose = () => {
    setResetPassword(false)
    handleProfileClose()
  }

  const handleResetClose = () => {
    setResetPassword(false)
  }

  const renderFields = () => {
    return (
      <>
        {formBuilderProps.map((data) => (
          <FormFieldView
            value={data.value}
            type={data?.type}
            // required={data.required}
            label={data.label}
            key={data?.id}
          />
        ))}
      </>
    )
  }

  return (
    <>
      {/* <ChangePassword
        isOpen={resetPassword}
        handleClose={handleClose}
        empId={userData?.id}
      /> */}
      <DialogModal
        isOpen={isOpen}
        onClose={handleClose}
        title={resetPassword ? 'Change Password' : 'My Profile'}
        // secondaryAction={handleClose}
        // onSubmit={handleResetPassword}
        // secondaryActionLabel="Close"
        // actionLabel="Change Password"
        small={true}
        body={
          <>
            {resetPassword ? (
              <ProfileChangePassword
                handleResetClose={handleResetClose}
                empId={userData?.id}
              />
            ) : (
              <div className="flex flex-col pb-5 min-h-[560px]">
                <div className="flex flex-col items-center">
                  <div className=" w-28 h-28 mb-2 rounded-full overflow-hidden">
                    <img
                      className="bg-cover"
                      src="/images/profile-default.png"
                    />
                  </div>
                  {userData.is_admin ? (
                    <span className="bg-success text-white text-xxs leading-4 flex items-center justify-center rounded-[4px] px-2 py-1">
                      Admin
                    </span>
                  ) : userData.is_operations_head ? (
                    <span className="bg-success text-white text-xxs leading-4 flex items-center justify-center rounded-[4px] px-2 py-1">
                      Operations Head
                    </span>
                  ) : (
                    ''
                  )}
                  <div>
                    <span className="text-lg font-bold text-primaryText">
                      {`${userData.first_name} ${userData.last_name || '--'}`}
                    </span>
                  </div>
                  <div className=" text-secondary font-medium text-sm">
                    {userData.username || '--'}
                  </div>
                </div>

                <div className="flex flex-col gap-3 my-5 flex-1">
                  {renderFields()}
                </div>
                <div className="w-full flex items-center justify-end mt-1">
                  <Button
                    onClick={handleResetPassword}
                    label="Change Password"
                  />
                </div>
              </div>
            )}
          </>
        }
      />
    </>
  )
}

export default Profile
