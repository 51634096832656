import moment from 'moment'

import {
  getNestedProperty,
  useCurrencyFormat,
} from '../../../../utilities/parsers'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({ onViewAction, statusClassGen }: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    const format = useCurrencyFormat
    switch (isCustom) {
      case 'amount':
        const propertyValue = getNestedProperty(row, key)
        const numbers =
          typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

        return {
          cell: numbers === 0 ? '--' : format(numbers),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      case 'bizpole_payable_amount':
        const property = getNestedProperty(row, key)
        const number = typeof property === 'string' ? parseFloat(property) : 0

        return {
          cell: number === 0 ? '--' : format(number),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      case 'status':
        return {
          cell: (
            <div className="text-capitalize">
              <span
                className={`statusLabel  ${
                  row.status?.name
                    ? statusClassGen(row?.status?.name)
                    : 'Active'
                }`}
              >
                {row?.status?.name}
              </span>
            </div>
          ),
          toolTip: getNestedProperty(row, key),
        }
      case 'request_date':
        return {
          cell: (
            <>
              {row?.request_date
                ? moment(row?.request_date).format('DD-MM-YYYY')
                : '--'}
            </>
          ),
        }
      case 'approved_date':
        return {
          cell: (
            <>
              {row?.approved_date
                ? moment(row?.approved_date).format('DD-MM-YYYY')
                : '--'}
            </>
          ),
        }
      case 'followup': {
        const followup_date = moment(row?.target_date)
        const today = moment(new Date())
        const formattedDate = moment(row?.target_date).format('DD-MM-yyyy')
        return {
          cell: (
            <>
              {today.isAfter(followup_date, 'day') ? (
                <span className="text-red selected-item text-sm inline-block">
                  {row?.target_date ? formattedDate : row?.target_date}
                </span>
              ) : row?.target_date ? (
                formattedDate
              ) : (
                row?.target_date
              )}
            </>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      }
      case 'task_details': {
        return {
          cell: (
            <div className="text-capitalize">
              {/* <span
                  className={`statusLabel  ${
                    row.status?.name
                      ? statusClassGen(row?.status?.name)
                      : 'Active'
                  }`}
                > */}
              {row?.task}
              {/* </span> */}
            </div>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      }
      default:
        return {
          cell: getNestedProperty(row, key),
          toolTip: getNestedProperty(row, key) ?? '',
        }
    }
  }
  const column = [
    {
      title: 'Requested ID ',
      field: 'approval_number',
      rowClick: (row: any) => onViewAction(row, 'linkTo'),
      link: true,
      fixed: true,
      ...defaultColumnProps3,
      customCell: true,
    },
    {
      title: 'Requested for ',
      field: 'model_name',
      renderCell: createRenderCell('approval_type.name'),
      rowClick: (row: any) => onViewAction(row, 'linkTo'),
      link: true,
      fixed: true,
      ...defaultColumnProps3,
      customCell: true,
    },
    {
      title: 'Requested by',
      field: 'task',
      ...defaultColumnProps3,
      renderCell: createRenderCell('created_by.name'),
      customCell: true,
    },
    {
      title: 'Requested on',
      field: 'category',
      ...defaultColumnProps3,
      renderCell: createRenderCell('request_date', 'request_date'),
      customCell: true,
    },
    {
      title: 'Requested to',
      field: 'reminder_date',
      renderCell: createRenderCell('assignee.name', 'remainder'),
      ...defaultColumnProps3,
      customCell: true,
    },
    {
      title: 'Request Status',
      field: 'name',
      renderCell: createRenderCell('status.name', 'status'),
      ...defaultColumnProps3,
      customCell: true,
    },
    {
      title: 'Target date',
      field: 'target_date',
      ...defaultColumnProps3,
      renderCell: createRenderCell('target_date', 'followup'),
      customCell: true,
      type: 'date',
    },
    {
      title: 'Amount',
      field: 'amount',
      renderCell: createRenderCell('amount', 'amount'),
      ...defaultColumnProps3,
      customCell: true,
      align: 'right',
    },
    {
      title: 'Bizpole Payable Amount',
      field: 'bizpole_payable_amount',
      ...defaultColumnProps3,
      renderCell: createRenderCell('bizpole_payable_amount'),
      colWidth: 220,
      customCell: true,
      align: 'right',
    },
    {
      title: 'Reviewed On',
      field: 'approved_date',
      renderCell: createRenderCell('approved_date', 'approved_date'),
      customCell: true,
      ...defaultColumnProps3,
    },

    {
      title: 'Files',
      field: 'name',
      renderCell: createRenderCell('files_count'),
      ...defaultColumnProps3,
      customCell: true,
    },
  ]

  return column
}
