import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../../components/app/formBuilder/index'
import CustomDrawer from '../../../../../components/common/drawer'
import { useCreateVendorCreditBankAccount } from '../api'
import { AddCreditBankAccountSchema, bankAccountFullSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  subSection?: boolean
  parentParams?: { id?: string; name?: string }
  handleCallback?: () => void
  type: any
  feeDetails: any
}

export default function AddBankAccount({
  isDrawerOpen,
  handleClose,
  handleCallback,
  parentParams,
  type,
  feeDetails,
}: Props) {
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    type = 'text'
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
  })
  const getAccountType = () => {
    const data = [
      { id: 'current', name: 'Current' },
      { id: 'savings', name: 'Savings' },
    ]
    return data
  }

  const formBuilderProps = [
    textField('display_name', 'Display Name', 'Enter Display Name', false),
    textField('account_name', 'Name In Account', 'Enter Name In Account', true),
    {
      name: 'account_type_name',
      label: 'Account Type',
      required: true,
      getData: getAccountType,
      async: true,
      id: 'account_type',
      descId: 'id',
      desc: 'name',
      type: 'auto_complete',
      spacing: 4,
      placeholder: 'Enter Account Type',
    },
    textField('account_number', 'Account Number', 'Enter Account Number', true),
    textField('bank_name', 'Bank Name', 'Enter Bank Name', true),
    textField('branch_name', 'Branch Name', 'Enter Branch Name', true),
    textField('ifsc_code', 'IFSC Code', 'Enter IFSC Code', true),
    {
      type: 'checkbox',
      label: 'Professional Fee',
      name: 'professional_fee',
      hidden: type !== 'credit_bank',
      disabled: feeDetails?.isset_professional_fee_bank_account,
    },
    {
      type: 'checkbox',
      label: 'Vendor Fee',
      name: 'vendor_fee',
      hidden: type !== 'credit_bank',
      disabled: feeDetails?.isset_penalty_bank_account,
    },
    {
      type: 'checkbox',
      label: 'Govt Fee',
      name: 'govt_fee',
      hidden: type !== 'credit_bank',
      disabled: feeDetails?.isset_govt_fee_bank_account,
    },
    {
      type: 'checkbox',
      label: 'GST',
      name: 'gst',
      hidden: type !== 'credit_bank',
      disabled: feeDetails?.isset_gst_bank_account,
    },
    {
      type: 'checkbox',
      label: 'Late Fee',
      name: 'late_fees',
      hidden: type !== 'credit_bank',
      disabled: feeDetails?.isset_late_fee_bank_account,
    },
    {
      type: 'checkbox',
      label: 'Govt Taxes',
      name: 'govt_taxes',
      hidden: type !== 'credit_bank',
      disabled: feeDetails?.isset_govt_taxes_bank_account,
    },
    {
      type: 'checkbox',
      label: 'Penalty',
      name: 'penalty',
      hidden: type !== 'credit_bank',
      disabled: feeDetails?.isset_penalty_bank_account,
    },
  ]

  useEffect(() => {
    methods.reset({
      professional_fee: feeDetails?.isset_professional_fee_bank_account,
      vendor_fee: feeDetails?.isset_penalty_bank_account ?? false,
      govt_fee: feeDetails?.isset_govt_fee_bank_account ?? false,
      gst: feeDetails?.isset_gst_bank_account ?? false,
      late_fees: feeDetails?.isset_late_fee_bank_account ?? false,
      govt_taxes: feeDetails?.isset_govt_taxes_bank_account ?? false,
      penalty: feeDetails?.isset_penalty_bank_account ?? false,
    })
  }, [isDrawerOpen, feeDetails])

  const methods = useForm<AddCreditBankAccountSchema>({
    resolver: zodResolver(bankAccountFullSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const handleSubmission = () => {
    methods.reset({
      display_name: '',
      account_name: '',
      account_number: '',
      account_type_name: '',
      bank_name: '',
      branch_name: '',
      ifsc_code: '',
      ///////////////////////////
      professional_fee: false,
      vendor_fee: false,
      govt_fee: false,
      gst: false,
      late_fees: false,
      govt_taxes: false,
      penalty: false,
      ///////////////////////////
    })
    handleCallback?.()
    handleClearAndClose()
  }
  // const { mutate, data, isLoading } =
  //   useCreateVendorBankAccount(handleSubmission)
  const {
    mutate: creditBank,
    data: creditBankData,
    isLoading: creditLoading,
  } = useCreateVendorCreditBankAccount(handleSubmission)
  const onSubmit = (form_data: AddCreditBankAccountSchema) => {
    if (type === 'credit_bank') {
      setTimeout(() => {
        creditBank({
          input: { ...form_data },
          id: parentParams?.id,
        })
      }, 500)
    }
  }

  useEffect(() => {
    handleCallback?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditBankData])

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({
      display_name: '',
      account_name: '',
      account_number: '',
      account_type_name: '',
      bank_name: '',
      branch_name: '',
      ifsc_code: '',
      ///////////////////////////
      professional_fee: false,
      vendor_fee: false,
      govt_fee: false,
      gst: false,
      late_fees: false,
      govt_taxes: false,
      penalty: false,
      ///////////////////////////
    })
    handleClose()
  }

  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      handleSubmit={handleSubmit((data) => onSubmit(data))}
      title="Add Bank Account"
      actionLoader={creditLoading}
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...methods}>
          <FormBuilder data={formBuilderProps} edit={true} />
        </FormProvider>
      </div>
    </CustomDrawer>
  )
}
