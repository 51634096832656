import { useEffect, useRef, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import ReactQuill from 'react-quill'

import DialogModal from '../modal/DialogModal'
import TextField from './TextField'

import 'react-quill/dist/quill.snow.css'

type RichTextEditorProps = {
  name: string
  control: Control<any>
  rules?: Record<string, any>
  error?: any
  placeholder?: string
  defaultValue?: string
  className?: string
}

const QuillEditor = ({
  control,
  name,
  error,
  defaultValue,
}: RichTextEditorProps) => {
  const quillRef = useRef<ReactQuill | null>(null)
  const [editorValue, setEditorValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [websiteLink, setWebsiteLink] = useState('')
  const [linkError, setLinkError] = useState('')

  // Add custom link handler during editor initialization
  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor()
      const toolbar = editor.getModule('toolbar')

      toolbar.addHandler('link', () => {
        const range = editor.getSelection()
        if (range) setIsOpen(true)
        // if (range) {
        //   const url = prompt('Enter the URL:')
        //   if (url) {
        //     const isValidUrl = /^(http|https):\/\/[^\s$.?#].[^\s]*$/.test(url)
        //     if (isValidUrl) {
        //       editor.format('link', url)
        //     } else {
        //       alert('Invalid URL. Please enter a valid URL')
        //     }
        //   }
        // } else {
        //   alert('Please select text to add a link.')
        // }
      })
    }
  }, [])

  // Toolbar configuration
  // const modules = {
  //   toolbar: [
  //     ['bold', 'italic', 'underline'], // Text formatting options
  //     [{ list: 'ordered' }, { list: 'bullet' }], // Lists
  //     ['link'], // Link option
  //   ],
  // }

  useEffect(() => {
    if (defaultValue) {
      setEditorValue(defaultValue)
    }
  }, [defaultValue])
  useEffect(() => {
    if (websiteLink) {
      const editor = quillRef?.current?.getEditor()
      const isValidUrl = /^(http|https):\/\/[^\s$.?#].[^\s]*$/.test(websiteLink)
      if (isValidUrl) {
        editor?.format('link', websiteLink)
      } else {
        setLinkError('Please enter a valid URL')
      }
    }
  }, [websiteLink])

  const handleClose = () => {
    setIsOpen(false)
    setLinkError('')
  }
  const handleSubmit = () => {
    const editor = quillRef?.current?.getEditor()
    if (!linkError) {
      editor?.format('link', websiteLink)
    } else {
      setLinkError('Please enter a valid URL')
    }
  }

  return (
    <div>
      <DialogModal
        isOpen={isOpen}
        onClose={() => handleClose()}
        secondaryAction={handleClose}
        secondaryActionLabel="Cancel"
        onSubmit={handleSubmit}
        actionLabel="save"
        body={
          <div className="flex flex-col gap-4">
            <div className="w-full flex flex-col gap-2">
              <TextField
                id="1"
                name="webLink"
                label="Enter the URL:"
                onChange={(e) => {
                  setLinkError('')
                  setWebsiteLink(e.target.value)
                }}
                placeholder=""
                disabled={false}
                required={false}
              />
              {linkError ? (
                <div className="text-error text-error-label mt-[1px]">
                  {linkError}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        }
      />
      <Controller
        name={name}
        control={control}
        defaultValue={editorValue}
        render={({ field }) => (
          <ReactQuill
            {...field}
            ref={quillRef}
            className="min-h-[200px]"
            value={field.value || ''}
            onChange={(value) => {
              setEditorValue(value)
              field.onChange(value) // Update React Hook Form state
            }}
            placeholder="Start typing..."
            // modules={modules}
          />
        )}
      />
      {error && error[name] && (
        <div className="text-error text-error-label mt-[1px]">
          {error[name]?.message || 'Error'}
        </div>
      )}
    </div>
  )
}

export default QuillEditor
