import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'

import {
  deleteData,
  getConfigData,
  getData,
  postData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { useSnackbarManager } from '../../components/common/snackbar'
import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

interface Params {
  [key: string]: string | number | boolean | any
}

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.ACCOUNT_LIST_URL, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}

const accountAgentFetchData = async (id: string, input?: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.ACCOUNT_AGENT}${id}/agents/`, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}
export const deleteAccoutAgent = (id: string, agentId: string) => {
  return deleteData(`${apiUrl.ACCOUNT_AGENT}${id}/agents/${agentId}`)
}
export const deleteAccountItem = (id: string) => {
  return deleteData(`${apiUrl.ACCOUNT_LIST_URL}${id}`)
}
export const useAccount = (input: QueryParams) => {
  return useQuery(['accounts', input], () => fetchData(input), {
    // refetchOnWindowFocus: true,
    //staleTime: 50000,
  })
}

export const createAccount = (input: any) => {
  return postData(apiUrl.ACCOUNT_CREATE, input)
}

export const useCreateAccount = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createAccount, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Account Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data.errors[0].message[0] ?? error.response.data.errors
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const useAccountAgents = (id: string, input?: QueryParams) => {
  return useQuery(
    ['account-agents', id],
    () => accountAgentFetchData(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}

export const addAgent = (input: { input: any; id: string }) => {
  return postData(`${apiUrl.ACCOUNT_AGENT}${input?.id}/agents/`, input?.input)
}

export const useAddAgent = (handleSubmission: (input: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(addAgent, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Agent Added successfully', { variant: 'success' })
    },
    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}
export const getAccountSummaryDetails = (id: any) => {
  return getData(`${apiUrl.ACCOUNT_CREATE}${id}`)
}

export const editAccount = (data: { input: any; id: string }) => {
  return updateData(`${apiUrl.ACCOUNT_CREATE}${data?.id}/`, data?.input)
}

export const useEditAccount = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editAccount, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Account updated successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const {
  exportAccounts,
  getbusIndusData,
  getSubIndusData,
  getLeadSource,
  getCountryState,
  getCutomerList,
  // getMultiDetails
} = {
  exportAccounts: (data: any) =>
    getData(buildUrlWithParams(apiUrl.COMPANY_EXPORT, data)),
  getLeadSource: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.LEAD_SOURCE, params)),
  getbusIndusData: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.BUSINESS_CATEGORY, params)),
  getSubIndusData: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.BUSINESS_CATEGORY, params)),
  getCountryState: () => getData(apiUrl.STATE_LIST),
  getCutomerList: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.CONTACT_LIST_URL, params)),
}

export const getAccountMergeDetails = (data: any) => {
  return postData(apiUrl.ACCOUNT_MULTIPLE_DETAILS, data)
}

export const mergeAccounts = (data: any, id?: string) => {
  return updateData(`${apiUrl.ACCOUNT_LINK}${id}/merge/`, data)
}
export const getAccountDuplicate = (data: any, config: AxiosRequestConfig) =>
  getConfigData(
    `${apiUrl.CONTACT_LIST_URL}possible-account-duplicates/${parseQueryParams(
      data ?? {}
    )}`,
    config
  )

export const listAccountDuplicate = (id?: string, data?: any) =>
  getData(
    `${apiUrl.CONTACT_LIST_URL}${id}/account-duplicates/${parseQueryParams(
      data
    )}`
  )

export const getAccountDetails = (id?: string) => {
  return getData(`${apiUrl.ACCOUNT_LIST_URL}${id}`)
}

export const setPrimaryContact = (id: string, input: any) => {
  return updateData(`${apiUrl.ACCOUNT_LINK}${id}/set-primary-contact/`, input)
}

export const fetchAccountOrders = async (pageParams?: string, id?: string) => {
  const response = await getData(
    `${apiUrl.ACCOUNT_CONTACT}/${id}/orders${parseQueryParams(pageParams)}`
  )
  return response
}
export const linkContactToAccount = (id: string, input: any) => {
  return postData(`${apiUrl.ACCOUNT_CONTACT_LINK}${id}/contacts/`, input)
}
export const linkAccountToContact = (id: string, input: any) => {
  return postData(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/`, input)
}
export const unLinkContact = (id: string, contactId: any) => {
  return deleteData(`${apiUrl.ACCOUNT_CONTACT_LINK}${id}/contacts/${contactId}`)
}
export const unLinkAccount = (id: string, contactId: any) => {
  return deleteData(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/${contactId}`)
}

export const updateAccountCre = (id: string, data: any) => {
  return postData(`${apiUrl.ACCOUNT_LINK}${id}/allocate-cre`, data)
}
export const getAccountSummary = (params: Params) => {
  return getData(`${apiUrl.ACCOUNT_SUMMARY}${parseQueryParams(params)}`)
}
