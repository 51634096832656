import { cloneElement, ReactElement } from 'react'

import { PermissionDataProps, useAuthStore } from '../store/authStore'
import { router_config } from './route.config'

export const isAllFranchisee = () =>
  useAuthStore.getState().franchisee?.franchisee_name === 'All Franchisee' ||
  false
export const isAdmin = () => useAuthStore.getState().userData?.is_admin || false
console.log(isAdmin(), isAllFranchisee())
export const hasPermission = ({ scopes }: { scopes: string[] }) => {
  const permissions = useAuthStore.getState().permissionData

  if (isAdmin() && !isAllFranchisee()) {
    return true
  }
  if (scopes.length === 0) {
    return true
  }

  const has_permission = permissions?.some((permission: PermissionDataProps) =>
    scopes?.some((sc) => permission.acl === sc)
  )
  return has_permission
}
export const checkMultiplePermission = (slug_key: string) => {
  const permissions = useAuthStore.getState().permissionData

  if (isAdmin() && !isAllFranchisee()) return true

  const currentRouteConfig = router_config[slug_key]
  if (currentRouteConfig && currentRouteConfig.permission_slugs.length > 0) {
    return permissions?.some((per) =>
      currentRouteConfig.permission_slugs.includes(per?.codename as string)
    )
  }
  return true
}

export const checkPermissionAny = (permissionAcl: string) => {
  const permissions = useAuthStore.getState().permissionData
  if (isAdmin() && !isAllFranchisee()) {
    return true
  }
  const check_permission = permissions?.some(
    (permission: any) => permission.codename === permissionAcl
  )
  return check_permission
}
export const blockActionsbyStatus = (
  currentStatus?: string,
  statusToBlock: string[] = [
    'cancelled',
    'cancelled_fully',
    'converted',
    'rejected',
    'order_cancelled',
    'order_completed_payment_done',
  ]
) => {
  if (currentStatus) {
    return statusToBlock?.some((status: string) => status === currentStatus)
  }

  return false
}
export const allowActionsByStatusExcept = (
  currentStatus?: string,
  statusToblock: string[] = [
    'cancelled',
    'cancelled_fully',
    'converted',
    'order_cancelled',
    'order_completed_payment_done',
    'rejected',
  ]
) => {
  if (currentStatus) {
    return statusToblock?.some((status: string) => status === currentStatus)
      ? false
      : true
  }
  return false
}
const PermissionsGate = ({
  children,
  errorMessage = 'No Permission',
  errorProps = null,
  scopes,
  overridePermission = false,
}: {
  children: any
  errorMessage?: string
  errorProps?: any
  scopes: string[]
  overridePermission?: boolean
}): ReactElement => {
  const permissionGranted =
    (isAdmin() && !isAllFranchisee()) || overridePermission
      ? (isAdmin() && !isAllFranchisee()) || overridePermission
      : hasPermission({ scopes })

  if (!permissionGranted && !errorProps) return <>{errorMessage}</>

  if (!permissionGranted && errorProps)
    return cloneElement(children, { ...errorProps })

  return <>{children}</>
}
export default PermissionsGate
