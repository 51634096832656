import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { deleteTask, getasks, getTaskDetails } from '../../../apis/common.apis'
import { MODULES } from '../../../configs/scopes.config'
import { useAuthStore } from '../../../store/authStore'
import { Button, DialogModal } from '../../common'
import Icons from '../../common/icons'
import { useSnackbarManager } from '../../common/snackbar'
import InfoBox from '../alertBox/infoBox'
import { getColumns } from './coloumns'
import CreateTask from './create'

type Props = {
  model_name: string
  disabled?: boolean
  checkModulePermission?: (key: string, sub: string) => boolean
}
export default function TaskComponent({
  model_name,
  disabled = false,
  checkModulePermission,
}: Props) {
  const [createOpen, setCreateOpen] = useState(false)
  const { search } = useLocation()
  const params = useParams()
  const [data, setData] = useState<any>()
  const [rowData, setRowData] = useState<any>()
  const { userData } = useAuthStore()
  const [viewMode, setViewMode] = useState(false)
  const [edit, setEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [pageParams, setPageParams] = useState({
    page_size: 30,
    page: 1,
    model_id: params?.id,
    model_name: model_name,
  })
  const [deleteModal, setDeleteModal] = useState(false)
  const [item, setItem] = useState<string>('')
  const { enqueueSnackbar } = useSnackbarManager()

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }
  const handleGetData = async () => {
    setIsLoading(true)
    const { data } = await getasks(pageParams)
    setData(data)
    setIsLoading(false)
  }
  const getTaskData = async (id: string) => {
    const { data } = await getTaskDetails(id)
    return data
  }
  useEffect(() => {
    handleGetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, pageParams])

  const handleAction = () => {
    setCreateOpen(true)
    setRowData({})
    setEdit(false)
    setViewMode(false)
  }
  const handleDeleteModal = (rowData: any) => {
    setItem(rowData.id)
    setDeleteModal(true)
  }
  const handleDelete = () => {
    deleteTask(item)
      .then((res) => {
        enqueueSnackbar(res.message ? res.message : 'Deleted successfully', {
          variant: 'success',
        })
        handleGetData()
        setDeleteModal(false)
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      })
  }
  const handleEdit = async (rowData: any) => {
    if (rowData?.id) {
      const data = await getTaskData(rowData?.id)
      setRowData(data)
      setCreateOpen(true)
      setViewMode(false)
      setEdit(true)
    }
  }
  const onViewAction = async (row: any) => {
    if (row?.id) {
      const data = await getTaskData(row?.id)
      setRowData(data)
      setViewMode(true)
      setCreateOpen(true)
    }
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(search)
    const id = urlParams.get('entity')
    if (id) {
      console.log(id)
      onViewAction({ id: id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])
  return (
    <div>
      <DialogModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => setDeleteModal(false)}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              'Deleting this item is an irreversible action. Are you sure you want to proceed with the deletion?'
            }
          />
        }
      />
      <QbsTable
        columns={getColumns({ onViewAction: onViewAction })}
        data={data?.results}
        pagination
        toolbar
        // selection
        isLoading={isLoading}
        // onSelect={handleOnSlect}
        search={false}
        sortColumn=""
        sortType="asc"
        tableHeaderActions={
          <div className="flex gap-2">
            <Button
              onClick={handleAction}
              label={'Add New'}
              icon="plus"
              hidden={!checkModulePermission?.('add', MODULES.task)}
              disabled={disabled}
            />
          </div>
        }
        actionProps={[
          ...(disabled
            ? []
            : [
                {
                  title: 'View',

                  action: (rowData: any) => onViewAction(rowData),
                  icon: <Icons name="eye" />,

                  // hidden: !checkModulePermission?.('change', MODULES.task),
                  toolTip: 'View',
                },
                {
                  title: 'Edit',

                  action: (rowData: any) => handleEdit(rowData),
                  icon: <Icons name="edit" />,

                  hidden: !checkModulePermission?.('change', MODULES.task),
                  toolTip: 'Edit',
                },
                {
                  title: 'Delete',
                  action: (rowData: any) => handleDeleteModal(rowData),
                  icon: <Icons name="delete" />,
                  hide: (row: any) => row.created_by?.id !== userData?.id,
                  hidden: !checkModulePermission?.('delete', MODULES.task),
                  toolTip: 'Delete',
                },
              ]),
        ]}
        paginationProps={{
          onPagination: onChangePage,
          total: data?.count,
          currentPage: pageParams?.page,
          rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
          onRowsPerPage: onChangeRowsPerPage,
          dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
            ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
            : [10, 20, 30, 50],
        }}
      />
      <CreateTask
        isDrawerOpen={createOpen}
        model_name={model_name}
        rowData={rowData}
        // isOwnTask={rowData?.created_by?.id === userData?.id}
        isOwnTask={checkModulePermission?.('change', MODULES.task)}
        edit={edit}
        disabled={disabled}
        hasPermission={checkModulePermission?.('change', MODULES.eventlog)}
        setViewMode={setViewMode}
        setEdit={setEdit}
        viewMode={viewMode}
        handleClose={() => setCreateOpen(false)}
        handleCallback={handleGetData}
        paramsId={params?.id}
      />
    </div>
  )
}
