import { useEffect, useState } from 'react'
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom'

import { TabItemProps } from '../../../../../common/types'
import InfoBox from '../../../../../components/app/alertBox/infoBox'
import { DialogModal, TabContainer } from '../../../../../components/common'
import DetailHeader from '../../../../../components/common/DetailTiles'
import { useSnackbarManager } from '../../../../../components/common/snackbar'
// import Loader from '../../../components/common/loader/PageLoader'
import { blockActionsbyStatus } from '../../../../../configs/permissionGate'
import { router_config } from '../../../../../configs/route.config'
import { useAppStore } from '../../../../../store/appStore'
import { checkPermission } from '../../../permission'
import { getBankAccountDetails, setPrimaryVendorBankAccount } from '../api'

const VendorCreditBankAccountDetails = () => {
  const navigate = useNavigate()
  const [edit] = useState(false)
  const [data, setData] = useState<any>()

  const [statusModal, setStatusModal] = useState(false)

  const { enqueueSnackbar } = useSnackbarManager()
  const { setIsLoading } = useAppStore()

  const tabData = [
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT_BASIC_INFO.label,
      id: 'SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT_BASIC_INFO',
    },
  ]

  const params = useParams()
  const [activeTab, setActiveTab] = useState<string>(
    'SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT_BASIC_INFO'
  )

  const haandleTabChange = (selected: TabItemProps) => {
    navigate(
      generatePath(router_config[selected.id]?.path as string, {
        id2: params.id2,
      })
    )
  }

  useEffect(() => {
    if (params.id && params.id2) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, params.id2])

  const getData = async () => {
    setIsLoading(true)
    await getBankAccountDetails(params.id, params.id2).then((res) => {
      setData(res?.data)
      setIsLoading(false)
    })
  }

  const basicData = {
    title: `${data?.account_name ?? '--'} `,
  }
  const headerMenuItems = [
    {
      label: 'Set as primary',
      Action: () => setStatusModal(true),
      id: 4,
      hidden: false,
    },
  ]

  const contextData = {
    setActiveTab: (id: string) => setActiveTab(id),
    edit: edit,
    detailsData: data,
    handleCallBack: getData,
  }
  const handleDisable = () => {
    return (
      blockActionsbyStatus(data?.order_status?.code) ||
      !checkPermission('change')
    )
  }

  const manageStatus = () =>
    setPrimaryVendorBankAccount(params.id, data?.id, true)

  const handleStatusChange = () => {
    manageStatus()
      .then((res) => {
        enqueueSnackbar(
          res.message ? res.message : 'Status updated successfully',
          {
            variant: 'success',
          }
        )

        getData()
        setStatusModal(false)
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      })
  }
  return (
    <div>
      <div>
        <DetailHeader
          headerMenuItems={data?.is_primary ? [] : headerMenuItems}
          disabled={handleDisable()}
          data={basicData}
        />
        {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      </div>
      <div className="flex gap-4">
        <TabContainer
          data={tabData}
          activeTab={activeTab}
          onClick={haandleTabChange}
        >
          <Outlet context={contextData} />
        </TabContainer>
      </div>
      <DialogModal
        isOpen={statusModal}
        onClose={() => setStatusModal(false)}
        title={'Are you sure?'}
        onSubmit={() => handleStatusChange()}
        secondaryAction={() => setStatusModal(false)}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={'Do you want to set this bank account as primary?'}
          />
        }
      />
    </div>
  )
}

export default VendorCreditBankAccountDetails
