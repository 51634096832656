import { useEffect, useState } from 'react'
import { useLocation, useOutletContext, useParams } from 'react-router-dom'

import { checkPermission } from '../../../../permission'
import BasicInformation from './basicInfo'

const FranchiseeCreditankAccountBasicInfo = () => {
  const queryparams = useLocation()
  const paramsEdit = queryparams?.state?.is_edit
  const params = useParams()
  const [editFlag, setEditFlag] = useState(false)

  const { setActiveTab, handleCallBack, detailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleCallBack: () => void
    detailsData?: any
  }>()
  const [data, setData] = useState<any>(detailsData)
  useEffect(() => {
    setActiveTab('SYSTEM_SETTINGS_FRANCHISEE_CREDIT_BANK_ACCOUNT_BASIC_INFO')
  }, [])
  useEffect(() => {
    setData(detailsData)
  }, [detailsData])

  const handleRefresh = () => {
    handleCallBack?.()
  }

  const handleEditButton = (flag: boolean) => {
    setEditFlag(flag)
  }
  const handlePermission = () => {
    return checkPermission('change')
  }
  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg gap-4 grid grid-cols-2">
      <BasicInformation
        data={data}
        id={params.id ?? ''}
        id2={params.id2 ?? ''}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        paramsEdit={paramsEdit}
        hasPermission={handlePermission()}
      />
    </div>
  )
}

export default FranchiseeCreditankAccountBasicInfo
