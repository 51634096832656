import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  getCollectablesList,
  getDeliverablesList,
  getOrderList,
  getServiceList,
  useFileAttachments,
} from '../../../../apis/common.apis'
import { getErrorMessage } from '../../../../utilities/parsers'
import CustomDrawer from '../../../common/drawer'
import { useSnackbarManager } from '../../../common/snackbar'
import FormBuilder from '../../formBuilder'
import {
  ACCEPTED_IMAGE_TYPES,
  attachmentsSchema,
  AttachmentsSchema,
} from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  paramsId: any
  handleCallback?: () => void
  model_name: string
  fromData?: any
}
let updateKey = 1
export default function CreateFilesAndAttachments({
  isDrawerOpen,
  handleClose,
  paramsId,
  handleCallback,
  model_name,
  fromData,
}: Props) {
  const handleClearAndClose = () => {
    handleReset()
    handleClose()
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
  })

  const [pages, setPages] = useState(1)
  const [delPage, setDelPage] = useState(1)
  const [colPage, setColPage] = useState(1)
  const { enqueueSnackbar } = useSnackbarManager()
  const [attachmentName, setAttachmentName] = useState('')
  const getOrder = async (value?: string, page?: number) => {
    const data = await getOrderList({
      search: value ?? '',
      page: page as number,
      account_id: model_name == 'CustomerCompany' ? paramsId : '',
      contact_id: model_name == 'Customer' ? paramsId : '',
    })

    const next = data.next ? pages + 1 : 0
    setPages(next)

    return data?.data?.results?.map((item: any) => ({
      ...item,
      order_number: item.order_number,
      order_id: item.id,
    }))
  }

  const getService = async () => {
    if (order_number_id) {
      const { data } = await getServiceList(order_number_id ?? '')
      const next = data.next ? pages + 1 : 0
      setPages(next)

      return data?.map((item: any) => ({
        ...item,
        service_name: item.service_name,
        service_id: item.service?.id,
      }))
    }
  }

  const getCollectables = async (value: any, page?: number) => {
    const { data } = await getCollectablesList({
      search: value ?? '',
      page: page,
      ...(model_name === 'OrderItemTaskEmployee'
        ? { order_item_task_employee_id: paramsId }
        : {}),
    })
    const next = data.next ? colPage + 1 : 0
    setColPage(next)
    return data?.results?.map((item: any) => ({
      ...item,
      name: item?.display_name,
    }))
  }
  const getDeliverables = async (value: any, page?: number) => {
    const { data } = await getDeliverablesList({
      search: value ?? '',
      page: page,
      ...(model_name === 'OrderItemTaskEmployee'
        ? { order_item_task_employee_id: paramsId }
        : {}),
    })
    const next = data.next ? delPage + 1 : 0
    setDelPage(next)
    return data?.results?.map((item: any) => ({
      ...item,
      name: item?.display_name,
    }))
  }
  const onInit = () => {
    return {
      name: attachmentName ?? '',
      model_name: model_name ?? '',
    }
  }
  useEffect(() => {
    methods.setValue('model_name', model_name ?? '')
    if (!methods.watch().attachment) {
      methods.setValue('name', '')
    } else {
      methods.setValue('name', methods.watch()?.attachment?.name as string, {
        shouldValidate: true,
      })
    }
    updateKey = updateKey + 1
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentName])
  const methods = useForm<AttachmentsSchema>({
    resolver: zodResolver(attachmentsSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })
  const [orderDisable, setOrderDisable] = useState(false)
  const [orderRequired, setOrderRequired] = useState(false)
  const [serviceDisable, setServiceDisable] = useState(false)
  const [prefillOrder, setPrefillOrder] = useState(false)
  const [prefillService, setPrefillService] = useState(false)

  const { mapType, document_type_name, order_number_id } = methods.watch()

  useEffect(() => {
    const disableBoth = ['Deal', 'Lead', 'Quote', 'CustomerCompany', 'Customer']
    const prefillAndDisableBoth = ['OrderItem', 'OrderItemTaskEmployee']

    const handleStateUpdate = () => {
      const isDisableBoth = disableBoth.includes(model_name)
      const isPrefillAndDisableBoth = prefillAndDisableBoth.includes(model_name)

      if (
        isDisableBoth &&
        (model_name === 'CustomerCompany' || model_name === 'Customer') &&
        document_type_name === 'Order Related'
      ) {
        return {
          orderDisable: false,
          serviceDisable: false,
          orderRequired: true,
        }
      } else if (isDisableBoth || isPrefillAndDisableBoth) {
        return {
          orderDisable: true,
          serviceDisable: true,
          orderRequired: false,
          prefillOrder: isPrefillAndDisableBoth,
          prefillService: isPrefillAndDisableBoth,
        }
      } else if (model_name === 'Order') {
        return { orderDisable: true, serviceDisable: false, prefillOrder: true }
      } else {
        return { orderDisable: false, serviceDisable: false }
      }
    }

    const {
      orderDisable,
      serviceDisable,
      orderRequired,
      prefillOrder,
      prefillService,
    } = handleStateUpdate()

    setOrderDisable(orderDisable)
    setServiceDisable(serviceDisable)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    orderRequired !== undefined && setOrderRequired(orderRequired)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    prefillOrder !== undefined && setPrefillOrder(prefillOrder)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    prefillService !== undefined && setPrefillService(prefillService)

    updateKey = updateKey + 1
  }, [model_name, document_type_name])
  const { setValue } = methods
  const handleOrderCallBack = () => {
    setValue('order_service_name', '', { shouldValidate: true })
    setValue('order_service_id', '', { shouldValidate: true })
  }
  const handleMpTypeCallback = () => {
    setValue('deliverable_id', '', { shouldValidate: true })
    setValue('collectable_id', '', { shouldValidate: true })
    setValue('deliverable_name', '', { shouldValidate: true })
    setValue('collectable_name', '', { shouldValidate: true })
    setDelPage(1)
    setColPage(1)
  }
  const handleClassificationCallBack = () => {
    methods.clearErrors('order_number_name')
  }
  const formBuilderProps = [
    {
      name: 'attachment',
      required: true,
      id: 'attachment',
      descId: 'id',
      type: 'file_upload',
      setAttachmentName: setAttachmentName,
      supportedExtensions: ACCEPTED_IMAGE_TYPES,
      supportedFiles: ACCEPTED_IMAGE_TYPES,

      selectedFiles: attachmentName
        ? {
            name: attachmentName ?? '',
          }
        : undefined,
    },
    {
      ...textField('name', 'File Name', 'Enter File Name', true),
      type: 'text',
      value: attachmentName[0] ?? '',
      required: true,
      disabled: false,
    },
    {
      name: 'document_type_name',
      label: 'Document Type / Classification',
      async: false,
      id: 'document_type_id',
      descId: 'id',
      initialLoad: true,
      required: true,
      desc: 'name',
      data: [
        { name: 'Order Related', id: 'order_related' },
        { name: 'Permanent', id: 'permanent' },
      ],
      type: 'custom_select',
      placeholder: 'Enter Document Type / Classification',
      handleCallBack: handleClassificationCallBack,
    },
    {
      name: 'order_number_name',
      label: 'Select Order Number',
      getData: getOrder,
      async: false,
      id: 'order_number_id',
      descId: 'order_id',
      initialLoad: true,
      disabled: orderDisable,
      required: orderRequired,
      desc: 'order_number',
      type: 'auto_complete',
      placeholder: 'Select Order Number',
      value: prefillOrder ? fromData?.order_number : null,
      handleCallBack: handleOrderCallBack,
    },
    {
      name: 'order_service_name',
      label: 'Select Order Service',
      getData: getService,
      async: false,
      id: 'order_service_id',
      descId: 'id',
      initialLoad: true,
      disabled: !order_number_id || serviceDisable,
      required: false,
      desc: 'service_name',
      type: 'auto_complete',
      placeholder: 'Select Order Service',
      value: prefillService ? fromData?.order_service : null,
    },
    {
      name: 'mapType',
      label: 'Select Collectables / Deliverables',
      id: 'id',
      descId: 'id',
      required: false,
      desc: 'name',
      handleCallBack: handleMpTypeCallback,
      // hidden: model_name !== 'OrderItemTaskEmployee',
      type: 'custom_select',
      data: [
        { name: 'Collectables', id: '1' },
        { name: 'Deliverables', id: '1' },
      ],
      placeholder: 'Select Collectables / Deliverables',
    },
    {
      name: 'collectable_name',
      label: 'Map File',
      getData: getCollectables,
      nextBlock: colPage,
      paginationEnabled: true,
      async: true,
      id: 'collectable_id',
      descId: 'id',
      initialLoad: true,
      hidden: !mapType || mapType !== 'Collectables',
      required: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Map File',
    },
    {
      name: 'deliverable_name',
      label: 'Map File',
      getData: getDeliverables,

      id: 'deliverable_id',
      paginationEnabled: true,
      nextBlock: delPage,
      async: true,
      hidden: !mapType || mapType !== 'Deliverables',
      descId: 'id',
      initialLoad: true,
      required: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Map File',
    },
  ]

  const handleSubmission = () => {
    methods.reset()
    handleClearAndClose()
    handleCallback?.()
  }
  const handleReset = () => {
    setAttachmentName('')
    methods.reset({
      attachment: undefined,
      document_type_id: undefined,
      document_type_name: undefined,
      name: undefined,
      order_number_id: fromData?.order_id ?? undefined,
      order_number_name: fromData?.order_number ?? undefined,
      order_service_name: fromData?.order_service ?? undefined,
      order_service_id: fromData?.order_service_id ?? undefined,
    })
  }
  useEffect(() => {
    handleReset()
    getOrder()
    getService()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  useEffect(() => {
    handleReset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen])
  const { mutate, isLoading } = useFileAttachments(handleSubmission)
  const onSubmit = (data: AttachmentsSchema) => {
    const formData = new FormData()
    formData.append('account_id', fromData?.account_id ?? '')
    formData.append('contact_id', fromData?.contact_id ?? '')

    formData.append('model_id', paramsId)
    formData.append('model_name', model_name)
    if (data?.document_type_id) {
      formData.append('file_classification', data.document_type_id)
    }
    formData.append('order_id', data.order_number_id ?? '')
    formData.append('service_id', data.order_service_id ?? '')

    if (data?.name) {
      formData.append('attachment_name', data.name)
    }
    if (data?.attachment) {
      formData.append('attachment', data?.attachment)
    }
    if (data?.mapType && data?.mapType === 'Collectables') {
      if (data?.collectable_id) {
        formData.append('collectable_id', data?.collectable_id as string)
      } else {
        enqueueSnackbar(getErrorMessage('Collectables is required'), {
          variant: 'error',
        })
        return
      }
    }
    if (data?.mapType && data?.mapType === 'Deliverables') {
      if (data?.deliverable_id) {
        formData.append('deliverable_id', data?.deliverable_id as string)
      } else {
        enqueueSnackbar(getErrorMessage('Deliverables is required'), {
          variant: 'error',
        })
        return
      }
    }
    if (model_name === 'CustomerCompany' || model_name === 'Customer') {
      if (data.document_type_id == 'permanent' || data.order_number_id) {
        mutate(formData)
      } else {
        enqueueSnackbar(getErrorMessage('Order Number is Mandatory'), {
          variant: 'error',
        })
      }
    } else {
      mutate(formData)
    }
  }

  const { handleSubmit } = methods
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      handleSubmit={handleSubmit((data) => onSubmit(data))}
      title="File Upload"
      disableSubmit={isLoading}
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...methods}>
          <FormBuilder data={formBuilderProps} edit={true} key={updateKey} />
        </FormProvider>
      </div>
    </CustomDrawer>
  )
}
