export const GetFilterDetails = () => {
  return [
    {
      type: 'date_picker',
      selectRange: true,
      desc: 'startDate',
      descSecondary: 'endDate',
      isPrimary: true,
      name: 'Allocation Date',
    },
  ]
}
