import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../../components/app/formBuilder/index'
import CustomDrawer from '../../../../../components/common/drawer'
import { useMasterData } from '../../../../../store/masterDataStore'
import { getPaymentTypes, useAddPayment } from '../../../api'
import { ACCEPTED_IMAGE_TYPES, paymentSchema, PaymentSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  parentParams?: { id?: string; name?: string }
  handleCallback?: () => void
}

export default function Create({
  isDrawerOpen,
  handleClose,
  parentParams,
  handleCallback,
}: Props) {
  const { masterData } = useMasterData()

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    spacing = 4,
    type = 'text'
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
    spacing: spacing,
  })
  useEffect(() => {
    methods.reset({ is_advance: 'false' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen])
  const getPaymentType = async () => {
    const res = await getPaymentTypes()
    return res?.data?.results
  }
  const formBuilderProps = [
    {
      name: 'additional_payment_type_id',
      label: 'Payment Type',
      getData: getPaymentType,
      async: false,
      id: 'additional_payment_type',
      descId: 'id',
      initialLoad: true,
      // handleCallBack: handleApprovalforCallBack,
      required: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Request for',
    },
    {
      ...textField(
        'transaction_date',
        'Transaction Date',
        'Enter transaction date',
        true
      ),
      maxDate: new Date(),
      type: 'date',
    },

    textField('transaction_id', 'Transaction ID', 'Enter transaction id', true),
    {
      ...textField('amount', 'Amount', 'Amount', true, 8, 'number'),
      type: 'number',
      id: 'amount',
    },
    {
      name: 'payment_method_name',
      label: 'Payment Method',
      required: true,
      data: masterData?.payment_methods,
      async: false,
      id: 'payment_method',
      paginationEnabled: false,
      descId: 'id',
      initialLoad: true,
      desc: 'name',
      type: 'custom_search_select',
      placeholder: 'Enter Payment Method',
    },
    {
      ...textField(
        'internal_notes',
        'Internal Notes',
        'Enter internal notes',
        false
      ),
      type: 'textarea',
      disabled: false,
    },
    textField('receipt_number', 'Receipt number', 'Enter Receipt number', true),
    {
      name: 'document',
      required: false,
      id: 'document',
      descId: 'id',
      type: 'file_upload',
      acceptedFiles: 'JPEG,JPG,PNG,PDF',
      supportedExtensions: ACCEPTED_IMAGE_TYPES,
      supportedFiles: ACCEPTED_IMAGE_TYPES,
    },
  ]

  const methods = useForm<PaymentSchema>({
    resolver: zodResolver(paymentSchema),
    mode: 'onChange',
    defaultValues: { is_advance: 'false' },
    reValidateMode: 'onChange',
  })

  const handleSubmission = () => {
    methods.reset()
    handleClearAndClose()
    handleCallback?.()
  }
  const { mutate, data, isLoading } = useAddPayment(handleSubmission)
  const onSubmit = (data: any) => {
    const formData = new FormData()

    Object.keys(data)?.forEach((item: string) => {
      if (item !== 'additional_payment_type_id') {
        if (item === 'transaction_date') {
          formData.append(
            'transaction_date',
            moment(data.transaction_date).format('YYYY-MM-DD')
          )
        } else if (item === 'document') {
          if (
            typeof data?.document !== 'string' &&
            data.document !== undefined
          ) {
            formData.append('document', data?.document)
          }
        } else if (item === 'is_advance') {
          formData.append('is_advance', data.is_advance ?? 'false')
        } else {
          formData.append(item, data[item] ?? '')
        }
      }
    })

    formData.append('payment_type', 'manual')
    mutate({
      input: formData,
      id: parentParams?.id,
    })
  }

  useEffect(() => {
    if (data) {
      handleCallback?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({ is_advance: 'false' })
    handleClose()
  }
  return (
    <>
      {
        <CustomDrawer
          className="formDrawer"
          open={isDrawerOpen}
          actionLoader={isLoading}
          handleClose={() => handleClearAndClose()}
          handleSubmit={handleSubmit((data) => onSubmit(data))}
          title="Record Payment"
        >
          <div className="flex flex-col gap-4">
            <FormProvider {...methods}>
              <FormBuilder data={formBuilderProps} edit={true} />
              <div className={`customRadioButton w-auto`}>
                <label className={`labels label-text`}>Advance Payment</label>
                <div className="customRadio-field relative flex items-center gap-4">
                  <div className="flex item-center gap-1 my-2 cursor-pointer">
                    <input
                      type="radio"
                      value="true"
                      id="is_advance"
                      {...methods.register('is_advance')}
                    />
                    <label htmlFor="is_advance" className="cursor-pointer">
                      Yes
                    </label>
                  </div>
                  <div className="flex item-center gap-1 my-2 cursor-pointer">
                    <input
                      type="radio"
                      value="false"
                      id="is_advance2"
                      {...methods.register('is_advance')}
                    />
                    <label htmlFor="is_advance2" className="cursor-pointer">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </FormProvider>
          </div>
        </CustomDrawer>
      }
    </>
  )
}
