import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../components/app/alertBox/infoBox'
import { DialogModal } from '../../../../components/common'
import Button from '../../../../components/common/buttons/Button'
import Icons from '../../../../components/common/icons'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { router_config } from '../../../../configs/route.config'
import { useVendorBankAccountFilterStore } from '../../../../store/filterSore/vendorBankAccountStore'
import { getErrorMessage } from '../../../../utilities/parsers'
import {
  deleteVendorBankAccount,
  setPrimaryVendorBankAccount,
  useVendorBankAccount,
} from './api'
import { getColumns } from './bankAccountColumns'
import AddBankAccount from './create'

const VendorBankAccount = () => {
  const navigate = useNavigate()
  const { pageParams, setPageParams } = useVendorBankAccountFilterStore()
  const { page, page_size, search, ordering } = pageParams
  const params = useParams()
  const [activeRow, setActiveRow] = useState<any>()
  const [accountOpen, setAccountOpen] = useState(false)
  const [statusOpen, setStatusOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()

  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
  }

  const { data, refetch, isFetching } = useVendorBankAccount(
    params.id as string,
    searchParams
  )

  // useEffect(() => {
  //   refetch()
  // }, [page, page_size, search, ordering, refetch])

  const { setActiveTab, handleParentCallback } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallback: () => void
  }>()

  useEffect(() => {
    setActiveTab('SYSTEM_SETTINGS_VENDOR_CREDIT_BANK_ACCOUNT')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onViewAction = (r: any, type: string) => {
    if (r?.id) {
      if (type === 'account_name') {
        return `${router_config.SYSTEM_SETTINGS_VENDORS.path}/${params.id}/${router_config.SYSTEM_SETTINGS_VENDOR_CREDIT_BANK_ACCOUNT.path}/${r?.id}/basic-info`
      } else if (type === 'edit') {
        return `${router_config.SYSTEM_SETTINGS_VENDORS.path}/${params.id}/${router_config.SYSTEM_SETTINGS_VENDOR_CREDIT_BANK_ACCOUNT.path}/${r?.id}/basic-info`
      }
    }
  }

  const handleRefresh = () => {
    refetch()
    handleParentCallback?.()
  }

  const handleAction = () => {
    setAccountOpen(true)
  }

  const handleSearch = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const manageStatus = () =>
    setPrimaryVendorBankAccount(params.id, activeRow.id, true)
  const handleStatusChange = () => {
    manageStatus()
      .then((res) => {
        enqueueSnackbar(
          res.message ? res.message : 'Status updated successfully',
          {
            variant: 'success',
          }
        )

        setStatusOpen(false)
        setActiveRow(null)
        refetch()
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      })
  }
  const handleDelete = () => {
    deleteVendorBankAccount(params.id, activeRow.id)
      .then(() => {
        enqueueSnackbar('Bank Account deleted successfully', {
          variant: 'success',
        })
        handleDeleteClose()
        handleRefresh()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message ??
              error?.response?.data?.errors[0]?.message[0] ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setActiveRow(null)
  }
  const handleDeletetOpen = (row: any) => {
    setActiveRow(row)
    setDeleteOpen(true)
  }

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }
  const handleStatusClose = () => {
    setStatusOpen(false)
    setActiveRow(null)
  }
  const handleStatusOpen = (row: any) => {
    setActiveRow(row)
    setStatusOpen(true)
  }
  return (
    <div>
      <QbsTable
        columns={getColumns({ onViewAction: onViewAction })}
        data={data?.data?.results ?? []}
        toolbar
        sortColumn=""
        isLoading={isFetching}
        search
        searchValue={pageParams?.search}
        onSearch={handleSearch}
        asyncSearch
        handleSearchValue={(key?: string) => handleSearch(key)}
        sortType="asc"
        tableHeaderActions={
          <div className="flex gap-2">
            <Button onClick={handleAction} label={'Add New'} icon="plus" />
          </div>
        }
        pagination
        paginationProps={{
          onPagination: onChangePage,
          total: data?.data?.count,
          currentPage: pageParams?.page,
          rowsPerPage: Number(pageParams.page_size ?? data?.data?.page_size),
          onRowsPerPage: onChangeRowsPerPage,
          dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
            ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
            : [10, 20, 30, 50],
        }}
        actionProps={[
          {
            title: 'Edit',
            action: (rowData) =>
              navigate(`${onViewAction(rowData, 'edit') ?? ''}`, {
                state: { is_edit: true },
              }),
            icon: <Icons name="edit" />,
            toolTip: 'Edit',
          },
          {
            icon: <Icons name="delete" />,
            action: (row) => handleDeletetOpen(row),
            title: 'Delete',
            toolTip: 'Delete',
          },
          {
            title: 'Set Primary Account',
            toolTip: 'Set Primary Account',
            action: (rowData) => handleStatusOpen(rowData),
            icon: <Icons name="star-icon" />,
            hide: (row) => row.is_primary,
          },
        ]}
      />
      <DialogModal
        isOpen={deleteOpen}
        onClose={() => handleDeleteClose()}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => handleDeleteClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox content={'Are you sure you want to delete bank account?'} />
        }
      />
      <DialogModal
        isOpen={statusOpen}
        onClose={() => handleStatusClose}
        title={'Are you sure?'}
        onSubmit={() => handleStatusChange()}
        secondaryAction={() => handleStatusClose}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={'Do you want to set this bank account as primary?'}
          />
        }
      />
      <AddBankAccount
        isDrawerOpen={accountOpen}
        handleClose={() => setAccountOpen(false)}
        handleCallback={accountOpen ? handleRefresh : undefined}
        parentParams={{ id: params.id }}
      />
    </div>
  )
}

export default VendorBankAccount
