import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../../components/app/formBuilder'
import CustomDrawer from '../../../../../components/common/drawer'
import Icons from '../../../../../components/common/icons'
import FormFieldView from '../../../../../components/common/inputs/FormFieldView'
import {
  getApprovalStatusList,
  getFilesAttachments,
  useReviewActivityRequests,
} from '../../../api'
import {
  ACCEPTED_IMAGE_TYPES,
  attachmentsSchema,
  AttachmentsSchema,
} from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  data: any
  disabled?: boolean
  handleCallback?: () => void
}
interface SubStatus {
  id: string
  name: string
  code: string
}

export default function ReviewActivityRequests({
  isDrawerOpen,
  handleClose,
  disabled = false,
  data,
  handleCallback,
}: Props) {
  const [formViewValues, setFormViewValues] = useState<any>([])
  const [reviewformViewValues, setreviewFormViewValues] = useState<any>([])

  const [files, setFiles] = useState<any>([])
  const handleClearAndClose = () => {
    handleReset()
    handleClose()
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
  })
  const getStatusList = async () => {
    const { data } = await getApprovalStatusList()
    const extractedStatuses: SubStatus[] = []

    data?.forEach((result: any) => {
      const { id, name, code } = result
      extractedStatuses.push({ id, name, code })
    })

    return extractedStatuses
  }

  const formBuilderProps = [
    {
      name: 'status_name',
      label: 'Review Status*',
      getData: getStatusList,
      async: false,
      id: 'status',
      descId: 'id',
      initialLoad: true,
      required: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Review Status*',
    },

    {
      ...textField('remark', 'Review Notes', 'Review Notes', false),
      type: 'textarea',
    },
    {
      name: 'attachments',
      required: false,
      id: 'attachments',
      label: 'Attach file',
      descId: 'id',
      isMultiple: true,
      supportedFiles: ACCEPTED_IMAGE_TYPES,
      supportedExtensions: ACCEPTED_IMAGE_TYPES,
      fileSize: 50,
      type: 'file_upload',
    },
  ]
  const methods = useForm<AttachmentsSchema>({
    resolver: zodResolver(attachmentsSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const handleSubmission = () => {
    methods.reset()
    handleClearAndClose()
    handleCallback?.()
  }
  const handleReset = () => {
    methods.reset({
      status: undefined,
      status_name: undefined,
      remark: undefined,
      attachments: undefined,
    })
  }
  // textarea
  useEffect(() => {
    handleReset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  const { mutate, isLoading } = useReviewActivityRequests(
    handleSubmission,
    data?.id
  )

  const onSubmit = (data: AttachmentsSchema) => {
    const formData = new FormData()
    formData.append('status', data?.status)
    formData.append('remark', data?.remark ?? '')

    data?.attachments?.forEach((atta: any) => {
      formData.append(`attachments`, atta)
    })

    mutate(formData)
  }
  const handleFilesAttachments = async () => {
    const { data: attachments } = await getFilesAttachments({
      model_id: data?.id,
      model_name: 'TaskApprovalRequest',
    })
    setFiles(attachments?.results || [])
  }
  const handleFormViewValues = () => {
    const formViewArray = [
      { label: 'Requested by', value: data?.created_by?.name || '--' },
      {
        label: 'Requested on',
        value: data?.request_date
          ? moment(data.request_date).format('DD-MM-YYYY')
          : '--',
      },
      { label: 'Requested for', value: data?.approval_type?.name || '--' },
      ...(data?.amount && Number(data?.amount) > 0
        ? [{ label: 'Amount', value: data?.amount || '--' }]
        : []),
      { label: 'Notes', value: data?.request_remark || '--' },
      {
        label: 'Bizpole Payable Amount',
        value: data?.bizpole_payable_amount || '--',
      },
    ]
    const reviewFormViewArray = [
      { label: 'Review Status', value: data?.status?.name || '--' },

      { label: 'Review Notes', value: data?.remark || '--' },
    ]

    setFormViewValues(formViewArray)
    setreviewFormViewValues(reviewFormViewArray)
    handleFilesAttachments()
  }
  const openFile = (url?: string) => {
    if (url) {
      window.open(url, '_blank')
    }
  }
  const { handleSubmit } = methods
  useEffect(() => {
    if (data?.id) {
      handleFormViewValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      actionLoader={isLoading}
      handleSubmit={
        disabled ? undefined : handleSubmit((data) => onSubmit(data))
      }
      title={disabled ? 'View Request' : 'Review Request'}
    >
      <div className="flex flex-col gap-4">
        {formViewValues.map((items: any, i: string) => (
          <FormFieldView
            value={items.value}
            label={items.label}
            key={i}
            type={items?.type}
          />
        ))}
        <div className=" ">
          <label className={`labels label-text`}>Attachments</label>
        </div>
        {files.map((item: any, k: number) => (
          <div
            key={item.id}
            className="flex items-center justify-between gap-1.5 px-2.5 py-2 bg-cardWrapperBg rounded-sm"
          >
            <Icons name="paper-clip" className="iconWidthSm iconBlack" />
            <p
              className="flex-1  text-sm font-medium cursor-pointer text-link"
              onClick={() => {
                openFile(item?.attachment)
              }}
            >
              {item?.attachment_name ?? `Attachment-${k + 1}`}
            </p>
          </div>
        ))}
        {disabled &&
          reviewformViewValues.map((items: any, i: string) => (
            <FormFieldView
              value={items.value}
              label={items.label}
              key={i}
              type={items?.type}
            />
          ))}
        {!disabled && (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={true} />
          </FormProvider>
        )}
      </div>
    </CustomDrawer>
  )
}
