import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import apiUrl from '../../../../apis/api.url'
import InfoBox from '../../../../components/app/alertBox/infoBox'
import ManagePayment from '../../../../components/app/payment/manage'
import ShareReceipt from '../../../../components/app/payment/receipt/shareReceipt'
import { DialogModal, TextArea } from '../../../../components/common'
import Button from '../../../../components/common/buttons/Button'
import Icons from '../../../../components/common/icons'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { router_config } from '../../../../configs/route.config'
import { MODULES } from '../../../../configs/scopes.config'
import { downloadFromServer } from '../../../../utilities/commonUtilities'
import { deletePayment, verifiyPayments } from '../../../payments/api'
import { UsegetAdditionalGovtFeeLists } from '../../api'
import { checkDetailPermission, checkPermission } from '../../permission'
import Create from './create/index'
import { getColumns } from './paymentColumns'
import SendLink from './sendLink/index'

const AdditionalGovtPayments = () => {
  // const { enqueueSnackbar } = useSnackbarManager()
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
  }>()

  const [isPaymentOpen, setIsPaymentOpen] = useState(false)
  // const [detailData, setDetailData] = useState<any>()
  const [isPaymentLinkOpen, setIsPaymentLinkOpen] = useState(false)
  const params = useParams()
  const { enqueueSnackbar } = useSnackbarManager()
  const [item, setItem] = useState<string | null>(null)
  const [remark, setRemark] = useState('')
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [shareReceipt, setshareReceipt] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState<string | null>(null)
  const [openManage, setOpenManage] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)

  const details = {
    id: params.id,
    additional_payment_type__category: 'govt',
  }

  const { data, isFetching, refetch } = UsegetAdditionalGovtFeeLists(details)
  const onViewAction = (r: any, from: string) => {
    if (from === 'account') {
      return `${router_config.ACCOUNTS.path}/${r?.account?.id}/summary`
    } else if (from === 'order') {
      return `/orders/${r?.order?.id}/summary`
    } else {
      handlePaymentRecieptOpen(r)
    }
  }

  const handleAction = () => {
    setIsPaymentOpen(true)
  }

  const handlePaymentLinkAction = () => {
    setIsPaymentLinkOpen(true)
  }

  const handleClose = () => {
    setIsPaymentOpen(false)
  }

  const handlePaymentLinkClose = (flag?: boolean) => {
    setIsPaymentLinkOpen(false)
    if (flag) {
      refetch()
    }
  }

  const handlePaymentRecieptOpen = (res: any) => {
    if (res.id) {
      window.open(`/payments/receipt/${res.id}`, '_blank')
    }
  }

  const handleCallback = () => {
    refetch()
  }
  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_SERVICE_ADDITIONAL_GOVT_PAYMENTS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleVerificationModal = (rowData: any) => {
    setItem(rowData.id)
    setConfirmationModal(true)
  }
  const handleVerificationModalClose = () => {
    setItem(null)
    setConfirmationModal(false)
  }

  const handleVerify = (from: string) => {
    verifiyPayments({
      id: item ?? '',
      input: {
        verification_status_type: from == 'verify' ? 'verify' : 'reject',
        remark: remark,
      },
    })
      .then((res) => {
        enqueueSnackbar(
          res.message
            ? res.message
            : from == 'verify'
              ? 'Verified successfully'
              : 'Rejected successfully',
          {
            variant: 'success',
          }
        )
        refetch()
        setConfirmationModal(false)
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.error?.message, {
          variant: 'error',
        })
      })
  }
  const handleDeleteModal = (rowData: any) => {
    setItem(rowData.id)
    setDeleteModal(true)
  }
  const handleDeleteModalClose = () => {
    setItem(null)
    setDeleteModal(false)
  }
  const handleDelete = () => {
    deletePayment(item as string)
      .then((res) => {
        enqueueSnackbar(res.message ? res.message : 'Deleted successfully', {
          variant: 'success',
        })
        refetch()
        // setSelectedRows(selectedRows?.filter((sel: any) => sel !== item) || [])
        setDeleteModal(false)
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.error?.message, {
          variant: 'error',
        })
      })
  }
  const handleDownload = (row: any) => {
    if (row?.receipt) {
      downloadFromServer(row.receipt, row.display_file_name)
    }
  }
  const handleShare = (row: any) => {
    setshareReceipt(true)
    setSelectedPayment(row?.id)
  }
  const handleEdit = (row: any) => {
    setOpenEdit(true)
    setSelectedPayment(row?.id)
    setOpenManage(true)
  }
  const handleCloseMange = (flag?: boolean) => {
    setOpenManage(false)
    setOpenEdit(false)
    setSelectedPayment(null)
    if (flag) {
      refetch()
    }
  }

  return (
    <div className=" p-4">
      <QbsTable
        data={data?.data ?? []}
        dataRowKey="id"
        toolbar={true}
        isLoading={isFetching}
        columns={
          getColumns({
            onViewAction: onViewAction,
          }) as any
        }
        columnToggle
        actionProps={[
          {
            icon: <Icons name="payment-receipt" />,
            action: (row) => handlePaymentRecieptOpen(row),
            title: 'View Receipt',
            toolTip: 'View Receipt',
            hidden: !checkDetailPermission('view', MODULES.receipt),
            hide: (row: any) =>
              row.payment_status_label === 'Pending' ||
              row?.payment_verification_status?.code === 'not_verified' ||
              row?.payment_verification_status?.code === 'rejected',
          },
          {
            icon: <Icons name="edit" />,
            action: (row) => handleEdit(row),
            title: 'Edit',
            toolTip: 'Edit',
            hide: (row) =>
              row.payment_verification_status &&
              row.payment_verification_status?.code !== 'not_verified',
            hidden: !checkPermission('change'),
          },

          {
            icon: <Icons name="delete" />,
            action: (row) => handleDeleteModal(row),
            title: 'Delete',
            hide: (row) =>
              row?.payment_verification_status?.code !== 'not_verified',
            toolTip: 'Delete',
            hidden: !checkPermission('delete'),
          },

          {
            icon: <Icons name="download" />,
            action: (row: any) => handleDownload(row),
            title: 'Download',
            toolTip: 'Download',
            hidden: !checkDetailPermission('download', MODULES.receipt),
            hide: (row: any) =>
              row.payment_status_label === 'Pending' ||
              row?.payment_verification_status?.code === 'not_verified',
          },
          {
            icon: <Icons name="share" />,
            action: (row: any) => handleShare(row),
            title: 'Share',
            toolTip: 'Share',
            hidden: !checkDetailPermission('share', MODULES.receipt),
            hide: (row: any) =>
              row.payment_status_label === 'Pending' ||
              row?.payment_verification_status?.code === 'not_verified',
          },
          {
            icon: <Icons name="payment-verify" />,
            action: (row) => handleVerificationModal(row),
            title: 'Payment Verification',
            hidden: !checkPermission('verify'),

            toolTip: 'Payment Verification',
            hide: (row: any) =>
              row.payment_verification_status &&
              row.payment_verification_status.code === 'not_verified' &&
              row.can_verify_payment == true
                ? false
                : true,
          },
        ]}
        tableHeaderActions={
          <div className="flex gap-2">
            <Button
              onClick={handleAction}
              label={'Record Payment'}
              className="se"
              icon="plus"
              outlined
            />
            <Button
              onClick={handlePaymentLinkAction}
              label={'Send Payment Link'}
              className="se"
              icon="plus"
            />
          </div>
        }
      />
      <Create
        isDrawerOpen={isPaymentOpen}
        parentParams={{ id: params?.id }}
        handleClose={handleClose}
        handleCallback={() => handleCallback()}
      />
      <SendLink
        isDrawerOpen={isPaymentLinkOpen}
        parentParams={{ id: params?.id }}
        handleClose={handlePaymentLinkClose}
        handleCallback={() => handleCallback()}
      />
      <ShareReceipt
        isDrawerOpen={shareReceipt}
        handleClose={() => setshareReceipt(false)}
        id={selectedPayment as string}
      />
      <ManagePayment
        isDrawerOpen={openManage}
        handleClose={handleCloseMange}
        edit={openEdit}
        handleCallback={openManage ? refetch : ''}
        id={selectedPayment as string}
        url={apiUrl.PAYMENT}
      />
      <DialogModal
        isOpen={deleteModal}
        onClose={() => handleDeleteModalClose()}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => handleDeleteModalClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              'Deleting this item is an irreversible action. Are you sure you want to proceed with the deletion?'
            }
          />
        }
      />

      <DialogModal
        isOpen={confirmationModal}
        onClose={() => handleVerificationModalClose()}
        title={'Verify Payment Entry'}
        onSubmit={() => handleVerify('verify')}
        secondaryAction={() => handleVerify('reject')}
        secondaryActionLabel="No, Reject"
        actionLabel="Yes, Verify"
        body={
          <InfoBox
            content={
              <div>
                <div className="mb-5">
                  Confirm a manual payment entry post bank/account
                  reconciliation. Please be aware that this action is
                  irreversible, and once verified, the payment entry cannot be
                  edited or deleted.
                </div>
                <TextArea
                  id="1"
                  name="reason"
                  label="Verification Remarks"
                  onChange={(e) => setRemark(e.target.value)}
                  placeholder="Enter Verification Remarks"
                  required={false}
                />
              </div>
            }
          />
        }
      />
    </div>
  )
}

export default AdditionalGovtPayments
