import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  patchData,
  postData,
  updateData,
} from '../../../../apis/api.helpers'
import apiUrl from '../../../../apis/api.url'
import { QueryParams } from '../../../../common/types'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import {
  getErrorMessage,
  parseQueryParams,
} from '../../../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

export const getBankAccountDetails = (id: any, id2: any) => {
  return getData(
    `${apiUrl.FRANCHISEE_DETAILS}${id}/credit_bank_accounts/${id2}/`
  )
}

export const createFranchiseeBankAccount = (data: {
  input: any
  id: string
}) => {
  return postData(
    `${apiUrl.FRANCHISEE_DETAILS}${data.id}/credit_bank_accounts/`,
    data.input
  )
}

export const useCreateFranchiseeCreditBankAccount = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createFranchiseeBankAccount, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Bank Account Added successfully', { variant: 'success' })
    },
    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const fetchFranchiseeBankAccount = async (
  id: any,
  input: QueryParams
) => {
  const url = buildUrlWithParams(
    `${apiUrl.FRANCHISEE_DETAILS}${id}/credit_bank_accounts`,
    {
      ...input,
    }
  )
  const response = await getData(url)
  return response
}

export const useFranchiseeCreditBankAccount = (
  id: string,
  input: QueryParams
) => {
  return useQuery(['franchise_credit_bank_account', id, input], () =>
    fetchFranchiseeBankAccount(id, input)
  )
}

export const editBankAccount = (data: {
  input: any
  id: string
  id2: string
}) => {
  return updateData(
    `${apiUrl.FRANCHISEE_DETAILS}${data?.id}/credit_bank_accounts/${data?.id2}/`,
    data?.input
  )
}

export const useEditBankAccount = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editBankAccount, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const deleteFranchiseeBankAccount = (id: any, id2: any) => {
  return deleteData(
    `${apiUrl.FRANCHISEE_DETAILS}${id}/credit_bank_accounts/${id2}`
  )
}
export const setPrimaryFranchiseeBankAccount = (
  id: any,
  id2: any,
  status: boolean
) => {
  return patchData(
    `${apiUrl.FRANCHISEE_DETAILS}${id}/credit_bank_accounts/${id2}/set_primary/`,
    {
      status: status,
    }
  )
}
