import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../../../components/app/formBuilder/index'
import { Button } from '../../../../../../components/common'
import Icons from '../../../../../../components/common/icons'
import FormFieldView from '../../../../../../components/common/inputs/FormFieldView'
import {
  AddCreditBankAccountSchema,
  bankAccountFullSchema,
} from '../../../bankAccounts/create/schema'
import { useEditBankAccount } from '../../api'

type Props = {
  data: any
  id: string
  id2: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  hasPermission?: boolean
  paramsEdit?: boolean
}

const BasicInformation = (props: Props) => {
  const {
    data,
    id,
    id2,
    handleRefresh,
    handleEditButton,
    editFlag,
    hasPermission,
    paramsEdit,
  } = props

  const [isEditing, setIsEditing] = useState(false)

  const onInit = () => {
    return {
      account_name: data?.account_name ?? '',
      account_number: data?.account_number ? data?.account_number : undefined,
      bank_name: data?.bank_name ?? '',
      branch_name: data?.branch_name ?? '',
      ifsc_code: data?.ifsc_code ?? '',
      account_type_name: data?.account_type?.label ?? '',
      account_type: data?.account_type?.value ?? '',
      display_name: data?.display_name ?? '',

      professional_fee: data?.professional_fee ?? false,
      vendor_fee: data?.vendor_fee ?? false,
      govt_fee: data?.govt_fee ?? false,
      gst: data?.gst ?? false,
      late_fees: data?.late_fees ?? false,
      govt_taxes: data?.govt_taxes ?? false,
      penalty: data?.penalty ?? false,
    }
  }
  const methods = useForm<AddCreditBankAccountSchema>({
    resolver: zodResolver(bankAccountFullSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })
  useEffect(() => {
    if (paramsEdit) {
      setIsEditing(true)
    }
  }, [paramsEdit])

  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    value: string,
    type = 'text'
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    value,
    ...(required ? { required: true } : {}),
  })

  const getAccountType = () => {
    const data = [
      { id: 'current', name: 'Current' },
      { id: 'savings', name: 'Savings' },
    ]
    return data
  }

  const formBuilderProps = [
    textField(
      'display_name',
      'Display Name',
      'Enter Display Name',
      false,
      data?.display_name
    ),
    textField(
      'account_name',
      'Name In Account',
      'Enter Name In Account',
      true,
      data?.account_name
    ),
    {
      name: 'account_type_name',
      label: 'Account Type',
      required: true,
      getData: getAccountType,
      async: true,
      id: 'account_type',
      descId: 'id',
      desc: 'name',
      type: 'auto_complete',
      spacing: 4,
      placeholder: 'Enter Account Type',
      value: data?.account_type?.label,
    },
    textField(
      'account_number',
      'Account Number',
      'Enter Account Number',
      true,
      data?.account_number
    ),
    textField(
      'bank_name',
      'Bank Name',
      'Enter Bank Name',
      true,
      data?.bank_name
    ),
    textField(
      'branch_name',
      'Branch Name',
      'Enter Branch Name',
      true,
      data?.branch_name
    ),
    textField(
      'ifsc_code',
      'IFSC Code',
      'Enter IFSC Code',
      true,
      data?.ifsc_code
    ),
    {
      type: 'checkbox',
      label: 'Professional Fee',
      name: 'professional_fee',
      value: data?.professional_fee,
    },
    {
      type: 'checkbox',
      label: 'Vendor Fee',
      name: 'vendor_fee',
      value: data?.vendor_fee,
    },
    {
      type: 'checkbox',
      label: 'Govt Fee',
      name: 'govt_fee',
      value: data?.govt_fee,
    },
    {
      type: 'checkbox',
      label: 'GST',
      name: 'gst',
      value: data?.gst,
    },
    {
      type: 'checkbox',
      label: 'Late Fee',
      name: 'late_fees',
      value: data?.late_fees,
    },
    {
      type: 'checkbox',
      label: 'Govt Taxes',
      name: 'govt_taxes',
      value: data?.govt_taxes,
    },
    {
      type: 'checkbox',
      label: 'Penalty',
      name: 'penalty',
      value: data?.penalty,
    },
  ]

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
  }
  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    setIsEditing(!isEditing)
  }
  const { mutate, isLoading } = useEditBankAccount(handleSubmission)
  const onSubmit = (data: AddCreditBankAccountSchema) => {
    mutate({
      input: {
        ...data,
      },
      id,
      id2,
    })
  }

  const { handleSubmit } = methods

  useEffect(() => {
    handleEditButton(isEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])
  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={isEditing} />
          </FormProvider>
        ) : (
          formBuilderProps.map((addressInformation) => (
            <div key={data?.id} className=" col-span-2 md:col-span-1">
              <FormFieldView
                value={addressInformation.value}
                required={addressInformation?.required}
                type={addressInformation?.type}
                label={addressInformation.label}
              />
            </div>
          ))
        )}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="location-pin" />
          <h4 className=" font-bold text-primaryText">Basic Information</h4>
        </div>
        <div className="iconBlack cursor-pointer">
          {hasPermission && (
            <div className="iconBlack cursor-pointer">
              {isEditing ? (
                <div className="flex gap-2">
                  <div>
                    <Button
                      onClick={handleCancel}
                      size="xs"
                      label="Cancel"
                      outlined={true}
                    />
                  </div>
                  <div>
                    <Button
                      size="xs"
                      label="Save"
                      isLoading={isLoading}
                      outlined={false}
                      onClick={handleSubmit((data) => onSubmit(data))}
                    />
                  </div>
                </div>
              ) : !editFlag ? (
                <Icons onClick={handleEditClick} name="edit" />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">{renderFields()}</div>
    </div>
  )
}

export default BasicInformation
