import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { getCountry, getState } from '../../../../apis/common.apis'
import FormBuilder from '../../../../components/app/formBuilder/index'
import { Button } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import { useEditContact } from '../../api'
import { getContactDuplicateData } from '../../common/commonUtils'
import {
  addressInformationSchema,
  AddressInformationSchema,
} from '../../create/schema'
import { duplicateFields } from '../../store'

type Props = {
  data: any
  id: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  hasPermission?: boolean
}

const AddressInformation = (props: Props) => {
  const { data, id, handleRefresh, handleEditButton, editFlag, hasPermission } =
    props

  const [isEditing, setIsEditing] = useState(false)
  const [duplicates, setDuplicates] = useState<any>({})

  const onInit = () => {
    return {
      address_1: data?.addresses[0]?.address_1 ?? '',
      address_2: data?.addresses[0]?.address_2 ?? '',
      state: data?.addresses[0]?.state ?? '',
      city: data?.addresses[0]?.city ?? '',
      country: data?.addresses[0]?.country ?? '',
      pin_code: data?.addresses[0]?.pin_code ?? '',
    }
  }
  const methods = useForm<AddressInformationSchema>({
    resolver: zodResolver(addressInformationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })
  const { country } = methods.watch()
  const getCountryData = async (value: any) => {
    const { data } = await getCountry({ search: value, type: 'dropdown' })
    return data?.results?.map((item: any) => ({
      ...item,
      id: item.name,
      country_name: item.name,
    }))
  }
  const getStateData = async (value: any) => {
    const { data } = await getState({
      search: value,
      country__name: country,
    })
    return data?.results?.map((item: any) => ({
      ...item,
      state_name: item.state_display_name,
    }))
  }
  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    value: string,
    required = false,
    isDuplicateCheck = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    value,
    ...(required ? { required: true } : {}),
    ...(isDuplicateCheck ? { isDuplicateCheck: true } : {}),
  })

  const formBuilderProps = [
    textField(
      'address_1',
      'Adress Line 1',
      'Enter Adress Line 1',
      data?.addresses[0]?.address_1,
      false,
      true
    ),
    textField(
      'address_2',
      'Adress Line 2',
      'Enter Adress Line 2',
      data?.addresses[0]?.address_2,
      false,
      true
    ),
    {
      name: 'country',
      label: 'Country',
      required: true,
      getData: getCountryData,
      async: true,
      id: 'country  ',
      descId: 'code',
      initialLoad: false,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Select Country',
      value: data?.addresses[0]?.country,
      handleCallBack: () => {
        methods.setValue('state', '')
        methods.setValue('stateId', '')
      },
    },
    {
      name: 'state',
      label: 'State',
      required: true,
      getData: getStateData,
      async: true,
      id: 'state',
      descId: 'code',
      initialLoad: false,

      disabled: !country || country === '',
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Select state',
      value: data?.addresses[0]?.state,
    },
    textField('city', 'City', 'Enter City', data?.addresses[0]?.city, true),
    textField(
      'pin_code',
      'Pincode',
      'Enter Pincode',
      data?.addresses[0]?.pin_code
    ),
  ]

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
  }
  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    setIsEditing(!isEditing)
  }
  const { mutate, isLoading } = useEditContact(handleSubmission)
  const onSubmit = (data: AddressInformationSchema) => {
    mutate({ input: { ...data, type: 'address_info' }, id })
  }

  const { handleSubmit } = methods

  const handleDuplicate = async (fieldName: string, value?: string) => {
    const result = await getContactDuplicateData(fieldName, value, id)
    setDuplicates({ [fieldName]: result ?? [] })
  }

  const handleClearDuplicates = (fieldName: string) => {
    setDuplicates({ [fieldName]: [] })
  }
  useEffect(() => {
    handleEditButton(isEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])
  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder
              data={formBuilderProps}
              edit={isEditing}
              duplicates={duplicates}
              duplicateFields={duplicateFields}
              clearDuplicates={handleClearDuplicates}
              getDuplicates={handleDuplicate}
            />
          </FormProvider>
        ) : (
          formBuilderProps.map((addressInformation) => (
            <div key={data?.id} className=" col-span-2 md:col-span-1">
              <FormFieldView
                value={addressInformation.value}
                type={addressInformation?.type}
                required={addressInformation?.required}
                label={addressInformation.label}
              />
            </div>
          ))
        )}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="location-pin" />
          <h4 className=" font-bold text-primaryText">Address Information</h4>
        </div>
        <div className="iconBlack cursor-pointer">
          {hasPermission && (
            <div className="iconBlack cursor-pointer">
              {isEditing ? (
                <div className="flex gap-2">
                  <div>
                    <Button
                      onClick={handleCancel}
                      size="xs"
                      label="Cancel"
                      outlined={true}
                    />
                  </div>
                  <div>
                    <Button
                      size="xs"
                      label="Save"
                      outlined={false}
                      isLoading={isLoading}
                      onClick={handleSubmit((data) => onSubmit(data))}
                    />
                  </div>
                </div>
              ) : !editFlag ? (
                <Icons onClick={handleEditClick} name="edit" />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">{renderFields()}</div>
    </div>
  )
}

export default AddressInformation
