import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useRef, useState } from 'react'

import { deleteLead, getFullFranchiseeList } from '../../apis/common.apis'
import InfoBox from '../../components/app/alertBox/infoBox'
import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
import { DialogModal } from '../../components/common'
import Button from '../../components/common/buttons/Button'
import Icons from '../../components/common/icons'
// import Icons from '../../components/common/icons'
import ListingHeader from '../../components/common/ListingTiles'
// import { useLeadFilterStore } from '../../configs/scopes.config'
import PageTitle from '../../components/common/PageTitle'
import { useSnackbarManager } from '../../components/common/snackbar'
// import PageTiles from '../../components/common/PageTiles'
// import { router_config } from '../../configs/permissionGate'
import { router_config } from '../../configs/route.config'
import { useLeadFilterStore } from '../../store/filterSore/leadStore'
import { calcWindowHeight } from '../../utilities/calcHeight'
import { getSortedColumnName } from '../../utilities/parsers'
import {
  TABlE_COLUMNS,
  useTablePreference,
} from '../../utilities/tableprefrences/index'
import { updateLeadFranchisee, useLead } from './api'
import { GetFilterDetails } from './filterProperties'
import { getColumns } from './leadColumns'
import { checkPermission } from './permission'

// const allowCancel=false
const UnallottedLeadsList = () => {
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useLeadFilterStore()
  const { clearSelectedRows, advanceFilter, setAdvanceFilter } =
    useLeadFilterStore()
  const { getSavedColumns, handleSaveColumns } = useTablePreference()

  const {
    page,
    page_size,
    search,
    ordering,
    filters,
    filterProps,
    lead_status_group,
  } = pageParams
  // const [openExport, setExportOpen] = useState(false)
  const [franchisees, setFranchisees] = useState([])
  const [showDetail, setShowDetail] = useState(true)
  const [updateKey, setUpdateKey] = useState(1)
  // const [groupName, setGroupName] = useState<string[]>([])

  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
    lead_status_group: lead_status_group,

    ...filters,
  }

  const { ...pageFilters } = searchParams
  const { data, refetch, isFetching } = useLead({
    input: { ...pageFilters },
  })
  const [sortType, setSortType] = useState<'asc' | 'desc' | undefined>('asc')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [item, setItem] = useState<string>('')
  const { enqueueSnackbar } = useSnackbarManager()

  // const [groupNameCode, setGroupNameCode] = useState('pending')

  // useEffect(() => {
  //   statusGroupDetails()
  //   refetch()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page, page_size, search, ordering, filters, refetch, lead_status_group])
  // useEffect(() => {
  //   handleLeadGroup()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [groupNameCode])
  useEffect(() => {
    clearSelectedRows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSort = (orderColumn: any, orderDirection: any) => {
    setSortType(orderDirection)
    setSortColumn(orderColumn)
    setPageParams({
      ...pageParams,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  // const handleLeadGroup = () => {
  //   setPageParams({
  //     ...pageParams,
  //     lead_status_group: groupNameCode,
  //   })
  // }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }

  const handleColumnToggle = async (columns: any) => {
    handleSaveColumns(columns, TABlE_COLUMNS.UNALLOCATTED_LEAD)
  }

  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }
  const filterDetails = GetFilterDetails()
  const basicData = {
    title: 'Unallotted Leads',
    icon: 'user',
  }

  const onViewAction = (r: any) => {
    const path = `${router_config.UNALLOTTED_LEADS.path}/${r?.id}/summary`
    return path
  }
  const handleSHowHide = () => {
    return advanceFilter.filterParams?.find((item) => item.isChecked)
      ? true
      : false
  }
  const handleFilterChange = (dta: any, name: string, id: string) => {
    const currentParams = useLeadFilterStore.getState()?.pageParams || {}

    const getDefaultFilters = () => ({
      ...currentParams.filters,
      [id]: dta?.id ?? undefined,
    })

    const getDefaultFilterProps = () => ({
      ...currentParams.filterProps,
      [name]: dta[name] ?? undefined,
    })
    const filters = getDefaultFilters()
    const filterProps = getDefaultFilterProps()
    setPageParams({
      ...currentParams,
      page: 1,
      filters,
      filterProps,
    })
  }
  const handleFilterDateChange = (event: any, desc: any, desc2: any) => {
    setPageParams({
      ...pageParams,
      filterProps: {
        ...filterProps,
        [desc]: event?.value[0],
        [desc2]: event?.value[1],
      },
    })
  }
  useEffect(() => {
    if (
      filterProps.created_to_date ||
      (filterProps.created_to_date === null &&
        filterProps.created_from_date === null)
    ) {
      handleDatepicker()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.created_to_date])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        created_from_date: filterProps?.created_from_date
          ? moment(filterProps?.created_from_date).format('DD-MM-YYYY')
          : '',
        created_to_date: filterProps?.created_to_date
          ? moment(filterProps.created_to_date).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.created_to_date])

  useEffect(() => {
    if (
      filterProps.followup_to_date ||
      (filterProps.followup_to_date === null &&
        filterProps.followup_from_date === null)
    ) {
      handleFollowupDatepicker()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.followup_to_date])

  const handleFollowupDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        followup_from_date: filterProps?.followup_from_date
          ? moment(filterProps?.followup_from_date).format('DD-MM-YYYY')
          : '',
        followup_to_date: filterProps?.followup_to_date
          ? moment(filterProps.followup_to_date).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.followup_to_date])

  const [columns, setColumns] = useState<any>([])
  const handleFranchiseeeChange = (e: any, row: any) => {
    const details = { franchisee_id: e?.id ?? null }
    updateLeadFranchisee(details, row?.id)
      .then(() => {
        enqueueSnackbar('Franchisee  assigned successfully', {
          variant: 'success',
        })
        refetch()
      })

      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.error?.message, {
          variant: 'error',
        })
      })
      .finally(() => {
        setUpdateKey((p) => p + 1)
      })
  }
  useEffect(() => {
    setColumns(
      getSavedColumns(
        getColumns({
          onViewAction: onViewAction,
          franchisees,
          updateKey,
          handleFranchiseeeChange,
        }),
        TABlE_COLUMNS.UNALLOCATTED_LEAD
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [franchisees])

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        franchisees,
        updateKey,
        handleFranchiseeeChange,
      }),
    ])
    handleSaveColumns(
      getColumns({
        onViewAction: onViewAction,
        franchisees,
        updateKey,
        handleFranchiseeeChange,
      }),
      TABlE_COLUMNS.UNALLOCATTED_LEAD
    )
  }
  const getFranchiseeList = async () => {
    const { data } = await getFullFranchiseeList()

    const result = data.franchisees?.map((item: any) => ({
      ...item,
      franchisee_display_name:
        item?.franchisee_display_name ?? item?.franchisee_name,
    }))

    setFranchisees(result)
  }
  const hasFetchedFilterData = useRef(false)

  useEffect(() => {
    if (!hasFetchedFilterData.current) {
      getFranchiseeList()
      hasFetchedFilterData.current = true
    }
  }, [])
  const handleDeleteModal = (rowData: any) => {
    setItem(rowData.id)
    setDeleteModal(true)
  }
  const handleDelete = () => {
    deleteLead(item)
      .then((res) => {
        enqueueSnackbar(res.message ? res.message : 'Deleted successfully', {
          variant: 'success',
        })
        setSelectedRows(
          selectedRows?.filter(
            (sel: any) =>
              sel !==
              setSelectedRows(
                selectedRows?.filter((sel: any) => sel !== item) || []
              )
          ) || []
        )
        setSelectedRows(selectedRows?.filter((sel: any) => sel !== item) || [])
        refetch()
        setDeleteModal(false)
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      })
  }

  return (
    <>
      <DialogModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => setDeleteModal(false)}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              'Deleting this item is an irreversible action. Are you sure you want to proceed with the deletion?'
            }
          />
        }
      />

      <ListingHeader
        data={basicData}
        // onHandleExport={() => setExportOpen(true)}
        actionProps={{ hideExport: true }}
        // groupData={groupName}
        checkPermission={checkPermission}
        // setGroupNameCode={setGroupNameCode}
      />
      {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      <PageTitle data={data?.total} isLoading={isFetching} />
      <div className=" p-4">
        <QbsTable
          columns={columns}
          dataRowKey="id"
          // selectedRows={selectedRows}
          selection={false}
          isLoading={isFetching}
          handleColumnToggle={handleColumnToggle}
          toolbar
          search
          handleColumnSort={handleSort}
          height={calcWindowHeight(317)}
          data={data?.results ?? []}
          handleResetColumns={() => handleResetColums()}
          sortType={sortType}
          sortColumn={sortColumn}
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
              ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
              : [10, 20, 30, 50],
          }}
          primaryFilter={
            <PrimaryFilterComponent
              filterParams={filterDetails}
              handleFilterChange={handleFilterChange}
              filterProps={filterProps}
              advanceFilter={advanceFilter}
              handleFilterDateChange={handleFilterDateChange}
              isPrimary={true}
              setAdvanceFilter={setAdvanceFilter}
            />
          }
          advancefilter={
            showDetail && (
              <PrimaryFilterComponent
                filterParams={filterDetails}
                handleFilterDateChange={handleFilterDateChange}
                handleFilterChange={handleFilterChange}
                filterProps={filterProps}
                isPrimary={false}
                advanceFilter={advanceFilter}
              />
            )
          }
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
          tableHeaderActions={
            <div className="flex gap-2">
              {handleSHowHide() && (
                <Button
                  onClick={() => {
                    setShowDetail(!showDetail)
                  }}
                  label={showDetail ? 'Hide' : 'Show'}
                  className="se"
                  primary
                />
              )}
            </div>
          }
          actionProps={
            checkPermission?.('delete')
              ? [
                  {
                    title: 'Delete',
                    action: (rowData: any) => handleDeleteModal(rowData),
                    icon: <Icons name="delete" />,
                    toolTip: 'Delete',
                  },
                ]
              : []
          }
        />
      </div>
    </>
  )
}
export default UnallottedLeadsList
