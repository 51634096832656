import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  patchData,
  postData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { useSnackbarManager } from '../../components/common/snackbar'
import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

interface Params {
  [key: string]: string | number | boolean
}

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

export const createVendor = (input: any) => {
  return postData(apiUrl.VENDORS, input)
}

export const useCreateVendor = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createVendor, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Vendor Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.VENDORS, {
    ...input,
  })
  const response = await getData(url)
  return response
}

export const useVendor = (input: QueryParams) => {
  return useQuery(['employee', input], () => fetchData(input), {
    refetchOnWindowFocus: false,
  })
}

export const getPointOfSupply = (params: Params) =>
  getData(`${apiUrl.POINTOFSUPPLY}${parseQueryParams(params)}`)

export const getVendorDetails = (id: any) => {
  return getData(`${apiUrl.VENDORS}${id}/`)
}

export const editVendor = (data: { input: any; id: string }) => {
  return updateData(`${apiUrl.VENDORS}${data?.id}/`, data?.input)
}

export const useEditVendor = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editVendor, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const updateVendorStatus = (data: { input: any; id: string }) => {
  return postData(`${apiUrl.VENDORS}${data?.id}/change-status`, data?.input)
}

export const createVendorService = (data: { input: any; id: string }) => {
  return postData(`${apiUrl.VENDORS}${data.id}/services/`, data.input)
}

export const useCreateVendorService = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createVendorService, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Service Added successfully', { variant: 'success' })
    },
    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const createVendorRole = (data: { input: any; id: string }) => {
  return postData(`${apiUrl.VENDORS}${data.id}/group/`, data.input)
}
export const createVendorTeam = (data: { input: any; id: string }) => {
  return postData(`${apiUrl.VENDORS}${data.id}/teams/`, data.input)
}

export const useCreateVendorRoles = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createVendorRole, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Role Added successfully', { variant: 'success' })
    },
    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
export const useCreateVendorTeams = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createVendorTeam, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Team Added successfully', { variant: 'success' })
    },
    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

const fetchVendorServices = async (id: string, input: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.VENDORS}${id}/services`, {
    ...input,
  })
  const response = await getData(url)
  return response
}

export const useVendorServices = (id: string, input: QueryParams) => {
  return useQuery(
    ['franchise_services', id, input],
    () => fetchVendorServices(id, input),
    {
      // refetchOnWindowFocus: true,
      // staleTime: 0,
    }
  )
}

export const fetchVendorEmployee = async (id: string, input: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.VENDORS}${id}/employees`, {
    ...input,
  })
  const response = await getData(url)
  return response
}
export const fetchVendorTeams = async (id: string, input: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.VENDORS}${id}/teams`, {
    ...input,
  })
  const response = await getData(url)
  return response
}
export const fetchNotificationSettings = async (id: string) => {
  const url = `${apiUrl.VENDORS}${id}/get-notification-settings`

  const response = await getData(url)
  return response
}

export const useVendorEmployee = (id: string, input: QueryParams) => {
  return useQuery(
    ['vendor_employee', id, input],
    () => fetchVendorEmployee(id, input),
    {
      // refetchOnWindowFocus: true,
      // staleTime: 0,
    }
  )
}
export const useVendorTeams = (id: string, input: QueryParams) => {
  return useQuery(
    ['vendor_teams', id, input],
    () => fetchVendorTeams(id, input),
    {
      // refetchOnWindowFocus: true,
      // staleTime: 0,
    }
  )
}
export const useVendorRoles = (id: string, input: QueryParams) => {
  return useQuery(['vendor_roles', id, input], () => getRoles(id, input), {
    // refetchOnWindowFocus: true,
    // staleTime: 0,
  })
}

export const deleteVendorServices = (id: any, id2: any) => {
  return deleteData(`${apiUrl.VENDORS}${id}/services/${id2}`)
}
export const activateVendorServices = (id: any, id2: any) => {
  return patchData(`${apiUrl.VENDORS}${id}/services/${id2}/activate/`, {})
}
export const deactivateVendorServices = (id: any, id2: any) => {
  return patchData(`${apiUrl.VENDORS}${id}/services/${id2}/deactivate/`, {})
}

export const deleteVendorEmployee = (id: any) => {
  return deleteData(`${apiUrl.VENDORS}${id}/delete-franchisee-employee`)
}

export const changeVendorEmployeeStatus = (data: {
  input: any
  id: string
}) => {
  return postData(
    `${apiUrl.EMPLOYEE_LIST_URL}${data?.id}/change-status`,
    data?.input
  )
}
export const activateVendorEmployees = (id: any, id2: any) => {
  return patchData(`${apiUrl.VENDORS}${id}/employees/${id2}/activate/`, {})
}
export const deactivateVendorEmployees = (id: any, id2: any) => {
  return patchData(`${apiUrl.VENDORS}${id}/employees/${id2}/deactivate/`, {})
}
export const activateVendorTeam = (id: any, id2: any) => {
  return patchData(`${apiUrl.VENDORS}${id}/teams/${id2}/activate/`, {})
}
export const deactivateVendorTeam = (id: any, id2: any) => {
  return patchData(`${apiUrl.VENDORS}${id}/teams/${id2}/deactivate/`, {})
}
export const getRoles = (id: any, input: any) => {
  return getData(`${apiUrl.VENDORS}${id}/group/${parseQueryParams(input)}`)
}

export const getPaymentGateway = (id: string) => {
  return getData(`${apiUrl.VENDORS}${id}/get_payment_gateway_settings/`)
}
export const getVnedorPaymentGateway = (id: string) => {
  return getData(`${apiUrl.VENDORS}${id}/get_payment_gateway_settings/`)
}

export const getPaymentList = (input: any) => {
  return getData(
    `${apiUrl.PAYMENT_LIST}payment_gateways${parseQueryParams(input)}`
  )
}

export const updatePaymentGateway = (data: { input: any; id: string }) => {
  return updateData(
    `${apiUrl.VENDORS}${data?.id}/payment_gateway_settings/`,
    data?.input
  )
}

export const useUpdatePaymentGateway = (
  handleSubmission: (data: any) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(updatePaymentGateway, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Payment Gateway updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
export const updateNotificationSettings = (data: {
  input: any
  id: string
}) => {
  return postData(
    `${apiUrl.VENDORS}${data?.id}/notification-settings/`,
    data?.input
  )
}
export const useUpdateNotificationSettings = (
  handleSubmission: (data: any) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(updateNotificationSettings, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Payment Gateway updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
